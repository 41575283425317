@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

