/* overrides for @toast-ui/editor/dist/toastui-editor.css */

.toastui-editor-defaultUI {
  border: none;
}

.toastui-editor-toolbar {
  height: auto;
}

.toastui-editor-defaultUI-toolbar {
  background-color: transparent;
  border-bottom: 1px solid #dae3f9;
  border-top: 1px solid #dae3f9;
  padding: 1.75rem 1.5rem;
}

.toastui-editor-defaultUI-toolbar button {
  border: none;
  border-radius: 0;
  height: 2rem;
  margin: 0;
  width: 2rem;
}

.toastui-editor-toolbar-group {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.toastui-editor-defaultUI-toolbar button:first-child {
  margin-left: 0;
}

.toastui-editor-defaultUI .ProseMirror {
  padding: 5rem 16rem;
}

.toastui-editor-defaultUI-toolbar button:not(:disabled):hover {
  background-color: transparent;
  border: none;
}

.toastui-editor-toolbar-icons {
  background: none;
}

.toastui-editor-contents table th {
  background-color: #f3f3f3;
}

.toastui-editor-contents th p {
  color: #000;
}

.toastui-editor-toolbar-icons.heading:before {
  color: #595f77;
  content: "\e264";
  font-family: "Material Symbols Rounded";
  font-size: 1.5rem;
}

.toastui-editor-toolbar-icons.bold:before {
  color: #595f77;
  content: "\e238";
  font-family: "Material Symbols Rounded";
  font-size: 1.5rem;
}

.toastui-editor-toolbar-icons.italic:before {
  color: #595f77;
  content: "\e23f";
  font-family: "Material Symbols Rounded";
  font-style: normal;
  font-size: 1.5rem;
}

.toastui-editor-toolbar-icons.strike:before {
  color: #595f77;
  content: "\e257";
  font-family: "Material Symbols Rounded";
  font-size: 1.5rem;
}

.toastui-editor-toolbar-icons.table:before {
  color: #595f77;
  content: "\f191";
  font-family: "Material Symbols Rounded";
  font-size: 1.5rem;
}

.toastui-editor-tooltip {
  /* required to override inline style */
  display: none !important;
}

.toastui-editor-contents h1 {
  border-bottom: none;
}

.toastui-editor-contents h2 {
  border-bottom: none;
}
